import * as React from "react";
import Container from "@mui/material/Container";
import Intro from "./Intro";
import About from "./About";
import Projects from "./Projects";
import Contact from "./Contact";
import PageDivider from "./PageDivider.js";

export default function Content() {
  return (
    <Container>
      <Intro />
      <PageDivider sectionTitle="About" />
      <About />
      <PageDivider sectionTitle="Projects" />
      <Projects />
      <PageDivider sectionTitle="Contact" />
      <Contact />
    </Container>
  );
}
