import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import DescriptionIcon from "@mui/icons-material/Description";
import "@fontsource/oswald";
import { Link } from "react-scroll";
import Resume from "../assets/Jacob_Shivers_Resume.pdf";

function HideOnScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function NavBar(props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleButtonClick = (link) => {
    window.open(link);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "Signika",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                {"{JS}"}
              </Typography>

              <Box
                sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                fontFamily="oswald"
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  <Link
                    activeClass="active"
                    to="aboutSection"
                    spy={true}
                    smooth={true}
                    duration={700}
                  >
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">About</Typography>
                    </MenuItem>
                  </Link>

                  <Link
                    activeClass="active"
                    to="projectsSection"
                    spy={true}
                    smooth={true}
                    duration={700}
                  >
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">Projects</Typography>
                    </MenuItem>
                  </Link>

                  <Link
                    activeClass="active"
                    to="contactSection"
                    spy={true}
                    smooth={true}
                    duration={700}
                  >
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">Contact</Typography>
                    </MenuItem>
                  </Link>
                </Menu>
              </Box>
              <Typography
                variant="h5"
                noWrap
                component="a"
                href=""
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "Signika",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                {"{JS}"}
              </Typography>
              <Box
                sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                fontFamily="oswald"
              >
                <Link
                  activeClass="active"
                  to="aboutSection"
                  spy={true}
                  smooth={true}
                  duration={700}
                >
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "#feda84", display: "block" }}
                  >
                    About
                  </Button>
                </Link>
                <Link
                  activeClass="active"
                  to="projectsSection"
                  spy={true}
                  smooth={true}
                  duration={700}
                >
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "#feda84", display: "block" }}
                  >
                    Projects
                  </Button>
                </Link>
                <Link
                  activeClass="active"
                  to="contactSection"
                  spy={true}
                  smooth={true}
                  duration={700}
                >
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "#feda84", display: "block" }}
                  >
                    Contact Me
                  </Button>
                </Link>
              </Box>

              <Box sx={{ flexGrow: 0 }} fontFamily="oswald">
                <Tooltip title="View Resume" placement="left" arrow>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DescriptionIcon />}
                    onClick={() => handleButtonClick(Resume)}
                  >
                    Resume
                  </Button>
                </Tooltip>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </React.Fragment>
  );
}
