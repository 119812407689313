import * as React from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import GitHubIcon from "@mui/icons-material/GitHub";
import DescriptionIcon from "@mui/icons-material/Description";
import Resume from "../assets/Jacob_Shivers_Resume.pdf";

const actions = [
  { icon: <LinkedInIcon />, name: "My LinkedIn" },
  { icon: <MailOutlineIcon />, name: "Contact Me" },
  { icon: <GitHubIcon />, name: "My GitHub" },
  { icon: <DescriptionIcon />, name: "My Resume" },
];

export default function BasicSpeedDial() {
  const handleButtonClick = (link) => {
    window.open(link);
  };

  return (
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      sx={{ position: "fixed", bottom: 16, right: 16, zIndex: 2 }}
      icon={<SpeedDialIcon />}
    >
      <SpeedDialAction
        icon={<LinkedInIcon />}
        tooltipTitle="My LinkedIn"
        // component="a"
        // href="https://linkedin.com/in/jacob-shivers"
        onClick={() =>
          handleButtonClick("https://linkedin.com/in/jacob-shivers")
        }
      />
      <SpeedDialAction
        icon={<GitHubIcon />}
        tooltipTitle="My GitHub"
        // component="a"
        // href="https://github.com/jtshivers"
        onClick={() => handleButtonClick("https://github.com/jtshivers")}
      />
      <SpeedDialAction
        icon={<MailOutlineIcon />}
        tooltipTitle="Contact Me"
        component="a"
        href="mailto:jacobshivers@protonmail.com"
      />
      <SpeedDialAction
        icon={<DescriptionIcon />}
        tooltipTitle="My Resume"
        onClick={() => handleButtonClick(Resume)}
      />
    </SpeedDial>
  );
}
