import * as React from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import VizSensor from "react-visibility-sensor";

export default function Contact() {
  const [active, setActive] = React.useState(false);
  return (
    <Container sx={{ height: "95vh", alignItems: "center" }}>
      <VizSensor
        onChange={(isVisible) => {
          if (!active) {
            setActive(isVisible);
          }
        }}
      >
        <Fade in={active} timeout={1000}>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ height: "100%" }}
          >
            <Grid item xs={2}>
              <Typography variant="h4">Want to connect?</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography component="h6" variant="h6" gutterBottom>
                Whether you want to discuss a new opportunity, have a question,
                or just want to say hi, I'm always happy to talk!
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                size="large"
                component="a"
                href="mailto:jacobshivers@protonmail.com"
              >
                Say Hi
              </Button>
            </Grid>
          </Grid>
        </Fade>
      </VizSensor>
    </Container>
  );
}
