import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Avatar from "@mui/material/Avatar";
import VizSensor from "react-visibility-sensor";
import css from "../assets/avatars/css.png";
import firebase from "../assets/avatars/firebase.png";
import git from "../assets/avatars/git.png";
import html from "../assets/avatars/html5.png";
import javascript from "../assets/avatars/javascript.png";
import mui from "../assets/avatars/material-ui.png";
import node from "../assets/avatars/nodejs.png";
import postgres from "../assets/avatars/postgresql.png";
import react from "../assets/avatars/react.png";
import redux from "../assets/avatars/redux.png";
import solidity from "../assets/avatars/solidity.png";

export default function About() {
  const [active, setActive] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  const updateMedia = () => {
    setWindowWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <Container sx={{ height: "100vh" }}>
      <Grid
        container
        direction="column"
        justifyContent="start"
        spacing={2}
        sx={{ marginTop: "5%" }}
      >
        <Grid item>
          <Fade in={active} timeout={1000}>
            <Typography variant="h5">SKILLED WITH: </Typography>
          </Fade>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <Grid item>
              <VizSensor
                onChange={(isVisible) => {
                  if (!active) {
                    setActive(isVisible);
                  }
                }}
              >
                <Fade in={active} timeout={1000}>
                  <Avatar
                    alt="JavaScript"
                    src={javascript}
                    sx={{
                      width: windowWidth / 15,
                      height: windowWidth / 15,
                    }}
                    variant="square"
                  />
                </Fade>
              </VizSensor>
            </Grid>
            <Grid item>
              <VizSensor
                onChange={(isVisible) => {
                  if (!active) {
                    setActive(isVisible);
                  }
                }}
              >
                <Fade
                  in={active}
                  timeout={1000}
                  style={{ transitionDelay: "100ms" }}
                >
                  <Avatar
                    alt="HTML5"
                    src={html}
                    sx={{
                      width: windowWidth / 15,
                      height: windowWidth / 15,
                    }}
                    variant="square"
                  />
                </Fade>
              </VizSensor>
            </Grid>
            <Grid item>
              <VizSensor
                onChange={(isVisible) => {
                  if (!active) {
                    setActive(isVisible);
                  }
                }}
              >
                <Fade
                  in={active}
                  timeout={1000}
                  style={{ transitionDelay: "200ms" }}
                >
                  <Avatar
                    alt="CSS3"
                    src={css}
                    sx={{
                      width: windowWidth / 15,
                      height: windowWidth / 15,
                    }}
                    variant="square"
                  />
                </Fade>
              </VizSensor>
            </Grid>
            <Grid item>
              <VizSensor
                onChange={(isVisible) => {
                  if (!active) {
                    setActive(isVisible);
                  }
                }}
              >
                <Fade
                  in={active}
                  timeout={1000}
                  style={{ transitionDelay: "300ms" }}
                >
                  <Avatar
                    alt="Node.js"
                    src={node}
                    sx={{
                      width: windowWidth / 15,
                      height: windowWidth / 15,
                    }}
                    variant="square"
                  />
                </Fade>
              </VizSensor>
            </Grid>
            <Grid item>
              <VizSensor
                onChange={(isVisible) => {
                  if (!active) {
                    setActive(isVisible);
                  }
                }}
              >
                <Fade
                  in={active}
                  timeout={1000}
                  style={{ transitionDelay: "400ms" }}
                >
                  <Avatar
                    alt="React"
                    src={react}
                    sx={{
                      width: windowWidth / 15,
                      height: windowWidth / 15,
                    }}
                    variant="square"
                  />
                </Fade>
              </VizSensor>
            </Grid>
            <Grid item>
              <VizSensor
                onChange={(isVisible) => {
                  if (!active) {
                    setActive(isVisible);
                  }
                }}
              >
                <Fade
                  in={active}
                  timeout={1000}
                  style={{ transitionDelay: "500ms" }}
                >
                  <Avatar
                    alt="Redux"
                    src={redux}
                    sx={{
                      width: windowWidth / 15,
                      height: windowWidth / 15,
                    }}
                    variant="square"
                  />
                </Fade>
              </VizSensor>
            </Grid>
            <Grid item>
              <VizSensor
                onChange={(isVisible) => {
                  if (!active) {
                    setActive(isVisible);
                  }
                }}
              >
                <Fade
                  in={active}
                  timeout={1000}
                  style={{ transitionDelay: "600ms" }}
                >
                  <Avatar
                    alt="PostgreSQL"
                    src={postgres}
                    sx={{
                      width: windowWidth / 15,
                      height: windowWidth / 15,
                    }}
                    variant="square"
                  />
                </Fade>
              </VizSensor>
            </Grid>
            <Grid item>
              <VizSensor
                onChange={(isVisible) => {
                  if (!active) {
                    setActive(isVisible);
                  }
                }}
              >
                <Fade
                  in={active}
                  timeout={1000}
                  style={{ transitionDelay: "700ms" }}
                >
                  <Avatar
                    alt="Git"
                    src={git}
                    sx={{
                      width: windowWidth / 15,
                      height: windowWidth / 15,
                    }}
                    variant="square"
                  />
                </Fade>
              </VizSensor>
            </Grid>
            <Grid item>
              <VizSensor
                onChange={(isVisible) => {
                  if (!active) {
                    setActive(isVisible);
                  }
                }}
              >
                <Fade
                  in={active}
                  timeout={1000}
                  style={{ transitionDelay: "800ms" }}
                >
                  <Avatar
                    alt="Solidity"
                    src={solidity}
                    sx={{
                      width: windowWidth / 15,
                      height: windowWidth / 15,
                    }}
                    variant="square"
                  />
                </Fade>
              </VizSensor>
            </Grid>
            <Grid item>
              <VizSensor
                onChange={(isVisible) => {
                  if (!active) {
                    setActive(isVisible);
                  }
                }}
              >
                <Fade
                  in={active}
                  timeout={1000}
                  style={{ transitionDelay: "900ms" }}
                >
                  <Avatar
                    alt="Firebase"
                    src={firebase}
                    sx={{
                      width: windowWidth / 15,
                      height: windowWidth / 15,
                    }}
                    variant="square"
                  />
                </Fade>
              </VizSensor>
            </Grid>
            <Grid item>
              <VizSensor
                onChange={(isVisible) => {
                  if (!active) {
                    setActive(isVisible);
                  }
                }}
              >
                <Fade
                  in={active}
                  timeout={1000}
                  style={{ transitionDelay: "1000ms" }}
                >
                  <Avatar
                    alt="MUI"
                    src={mui}
                    sx={{
                      width: windowWidth / 15,
                      height: windowWidth / 15,
                    }}
                    variant="square"
                  />
                </Fade>
              </VizSensor>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={{ height: "70%" }}
        spacing={2}
      >
        <Grid item>
          <VizSensor
            onChange={(isVisible) => {
              if (!active) {
                setActive(isVisible);
              }
            }}
          >
            <Fade in={active} timeout={1000}>
              <Typography variant="h5">
                I recently graduated from Fullstack Academy, one of the nation's
                leading Software Engineering bootcamps. While there, I learned
                to work with JavaScript, HTML5, CSS3, Node.js, Express, React,
                Redux, SQL, Sequelize, and PostgreSQL. Most recently, I've been
                fascinated by Web3 technologies, and have been learning to write
                smart contracts with Solidity.
              </Typography>
            </Fade>
          </VizSensor>
        </Grid>
        <Grid item>
          <VizSensor
            onChange={(isVisible) => {
              if (!active) {
                setActive(isVisible);
              }
            }}
          >
            <Fade in={active} timeout={1000}>
              <Typography variant="h5">
                Prior to becoming a software engineer, I worked as a freelance
                graphic designer, with several years of experience in design and
                marketing.
              </Typography>
            </Fade>
          </VizSensor>
        </Grid>
      </Grid>
    </Container>
  );
}
