import * as React from "react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Fade from "@mui/material/Fade";
import VizSensor from "react-visibility-sensor";

export default function PageDivider(props) {
  const [active, setActive] = React.useState(false);
  const { sectionTitle } = props;
  return (
    <Container sx={{ width: "100%" }}>
      <Box
        id={`${sectionTitle.toLowerCase()}Section`}
        sx={{ height: "2vh" }}
      ></Box>
      <VizSensor
        onChange={(isVisible) => {
          if (!active) {
            setActive(isVisible);
          }
        }}
      >
        <Fade in={active} timeout={700}>
          <Divider textAlign="left">{sectionTitle}</Divider>
        </Fade>
      </VizSensor>
    </Container>
  );
}
