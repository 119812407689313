import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Typed from "react-typed";

export default function Intro() {
  const [typed, setTyped] = React.useState();
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [typedStyles, setTypedStyles] = React.useState({ fontSize: "8rem" });

  const updateMedia = () => {
    setWindowWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  React.useEffect(() => {
    const wait = async () => {
      setTimeout(() => {
        setTyped(
          <Typed
            strings={["a programmer.", "a problem solver.", "Jacob Shivers."]}
            typeSpeed={40}
            backSpeed={40}
          />
        );
      }, 1000);
    };
    wait();
  }, []);
  React.useEffect(() => {
    if (windowWidth < 1130) {
      setTypedStyles({ fontSize: "5rem" });
    } else {
      setTypedStyles({ fontSize: "8rem" });
    }
  }, [windowWidth]);
  return (
    <Container sx={{ height: "100vh" }}>
      <Grid
        container
        direction="column"
        spacing={2}
        justifyContent="center"
        sx={{ height: "100%" }}
      >
        <Grid item>
          <Fade in timeout={1000}>
            <Typography variant="h2" component="div" gutterBottom>
              Hi, I'm
            </Typography>
          </Fade>
        </Grid>
        <Grid item>
          <Fade in timeout={1000} style={{ transitionDelay: "1000ms" }}>
            <Typography
              variant="h1"
              component="div"
              gutterBottom
              sx={typedStyles}
            >
              {typed}
            </Typography>
          </Fade>
        </Grid>
        <Grid item>
          <Fade in timeout={1000} style={{ transitionDelay: "6500ms" }}>
            <Typography variant="h4" component="div" gutterBottom>
              I am a Full Stack Software Engineer currently looking for new
              opportunities.
            </Typography>
          </Fade>
        </Grid>
      </Grid>
    </Container>
  );
}
//
