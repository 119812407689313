import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Carousel from "react-material-ui-carousel";
import Fade from "@mui/material/Fade";
import VizSensor from "react-visibility-sensor";
import inkBuzz from "../assets/projectGifs/sign-up.gif";
import schooley from "../assets/projectGifs/schooley.gif";

const images = [
  {
    label: "Schooley",
    imgPath: schooley,
    text: "A school district management system. Written with Node.js, React, Express and PostgreSQL",
    demoLink: "https://schooley.herokuapp.com",
    repoLink: "https://github.com/jtshivers/Schooley",
  },
  {
    label: "GreyShopper",
    imgPath: "https://imgur.com/WOUnYaD.png",
    text: "An e-commerce site devoted to grayscale. Built with Node.js, React, Redux, Express and PostgreSQL. Styled with Material UI.",
    demoLink: "https://greyshopper.herokuapp.com",
    repoLink:
      "https://github.com/Dodger-Blue-2202/GreyShopper/tree/mui-styling",
  },
  {
    label: "Ink Buzz",
    imgPath: inkBuzz,
    text: "An NFT Marketplace/Social Network designed for tattoo lovers. Built with Node.js, React and Firebase",
    demoLink: "https://ink-buzz.web.app",
    repoLink: "https://github.com/FSA-CapStone-TeamAwesome/ink-buzz-pwa",
  },
];

export default function Projects() {
  const [active, setActive] = React.useState(false);
  const handleButtonClick = (link) => {
    window.open(link);
  };
  return (
    <Container sx={{ height: "100vh", alignItems: "center" }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={{ height: "100%" }}
      >
        <VizSensor
          onChange={(isVisible) => {
            if (!active) {
              setActive(isVisible);
            }
          }}
        >
          <Fade in={active} timeout={1000}>
            <Paper elevation={4} sx={{ height: "85vh" }}>
              <Carousel
                autoPlay={false}
                animation="slide"
                navButtonsAlwaysVisible="true"
                indicators={false}
                sx={{
                  borderBottomLeftRadius: "0.2rem",
                  borderBottomRightRadius: "0.2rem",
                  height: "100%",
                }}
              >
                {images.map((image, index) => (
                  <Card key={index} sx={{ height: "85vh" }}>
                    <CardContent>
                      <Typography variant="body2">
                        {images[index].label}
                      </Typography>
                    </CardContent>
                    <CardMedia
                      component="img"
                      alt={image.label}
                      height="60%"
                      image={image.imgPath}
                    />
                    <Grid
                      container
                      direction="column"
                      spacing={2}
                      justifyContent="center"
                      sx={{ height: "20%" }}
                    >
                      <Grid item>
                        <CardContent>
                          <Typography variant="body2">{image.text}</Typography>
                        </CardContent>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justifyContent="end"
                      sx={{ height: "13%" }}
                    >
                      <Grid item>
                        <CardActions>
                          <Button
                            size="medium"
                            variant="contained"
                            onClick={() => handleButtonClick(image.demoLink)}
                          >
                            View Live Demo
                          </Button>
                          <Button
                            size="medium"
                            variant="contained"
                            onClick={() => handleButtonClick(image.repoLink)}
                          >
                            View Repo
                          </Button>
                        </CardActions>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </Carousel>
            </Paper>
          </Fade>
        </VizSensor>
      </Grid>
    </Container>
  );
}
