import "./App.css";
import NavBar from "./components/MuiNav";
import BasicSpeedDial from "./components/SpeedDial";
import Content from "./components/Content";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Signika from "@fontsource/signika";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#43457f",
      dark: "#2E294E",
      contrastText: "#ff9b83",
    },
    secondary: {
      main: "#feda84",
    },
    background: {
      paper: "#976393",
      default: "#011638",
    },
    info: {
      main: "#40376E",
    },
    text: {
      primary: "#ff9b83",
      hint: "rgba(0,0,0,0.38)",
      disabled: "rgba(191,6,6,0.38)",
      secondary: "#feda84",
    },
    divider: "#feda84",
  },
  typography: {
    h1: {
      fontFamily: "Signika",
      fontSize: "8rem",
      color: "#ff9b83",
    },
    h2: {
      fontFamily: "Signika",
      color: "#feda84",
    },
    h3: {
      fontFamily: "Signika",
      color: "#feda84",
    },
    h4: {
      fontFamily: "Signika",
    },
    h5: {
      fontFamily: "Signika",
    },
    h6: {
      fontFamily: "Signika",
    },
    button: {
      fontFamily: "Oswald",
      color: "#ff9b83",
    },
    body1: {
      fontFamily: "Signika",
      fontSize: "1.3rem",
      color: "#feda84",
    },
    body2: {
      fontFamily: "Signika",
      fontSize: "1.3rem",
      color: "#011638",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Content />
      <BasicSpeedDial />
    </ThemeProvider>
  );
}

export default App;
